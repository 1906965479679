import i18n from '@/i18n';

export const REPORT_TABS = {
    "DRIVE_UNIT": "tab-report-um",
    "EQUIPMENT": "tab-report-equipment",
    "TRIP": "tab-report-travel",
    "HARVEST_FRONT": "tab-report-front"
}

export const UM_HEADERS = [
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("harvesters.reports.currentStatus.headers.driveUnit"),
        type: "STRING",
        value: "driveUnitName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckHistoricalReport.HEADERS.HARVEST_FRONT"),
        type: "STRING",
        value: "harvestFrontName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdpReport.Headers.trips"),
        type: "STRING",
        value: "travelCounts",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.boxQuantity"),
        type: "STRING",
        value: "boxQuantity",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.averageHarvestedArea"),
        type: "STRING",
        value: "averageHarvestedAreaString",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.totalHarvestedArea"),
        type: "STRING",
        value: "totalHarvestedAreaString",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.averageWeight"),
        type: "STRING",
        value: "averageWeight",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.totalWeight"),
        type: "STRING",
        value: "totalWeight",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.averageTCH"),
        type: "STRING",
        value: "averageTCH",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.totalTCH"),
        type: "STRING",
        value: "totalTCH",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.Origin.Headers.timeInEquipment"),
        type: "STRING",
        value: "timeInEquipment",
    }
];
export const EQUIPMENT_HEADERS = [
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.HEADERS.FLEET"),
        type: "STRING",
        value: "fleetName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("caneEquipments.title"),
        type: "STRING",
        value: "caneEquipmentName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("harvesters.reports.currentStatus.headers.driveUnit"),
        type: "STRING",
        value: "driveUnitName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckHistoricalReport.HEADERS.HARVEST_FRONT"),
        type: "STRING",
        value: "harvestFrontName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdpReport.Headers.trips"),
        type: "STRING",
        value: "travelCounts",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.boxQuantity"),
        type: "STRING",
        value: "boxQuantity",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.averageHarvestedArea"),
        type: "STRING",
        value: "averageHarvestedAreaString",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.totalHarvestedArea"),
        type: "STRING",
        value: "totalHarvestedAreaString",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.averageWeight"),
        type: "STRING",
        value: "averageWeight",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.totalWeight"),
        type: "STRING",
        value: "totalWeight",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.averageTCH"),
        type: "STRING",
        value: "averageTCH",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.totalTCH"),
        type: "STRING",
        value: "totalTCH",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.Origin.Headers.timeInEquipment"),
        type: "STRING",
        value: "timeInEquipment",
    }
];
export const TRAVEL_HEADERS = [
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.HEADERS.FLEET"),
        type: "STRING",
        value: "fleetName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.driver"),
        type: "STRING",
        value: "driverName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckCurrentStateReport.origin.headers.truckName"),
        type: "STRING",
        value: "truckName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("caneEquipments.title"),
        type: "STRING",
        value: "caneEquipmentName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckHistoricalReport.HEADERS.HARVEST_FRONT"),
        type: "STRING",
        value: "harvestFrontName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("harvesters.reports.currentStatus.headers.driveUnit"),
        type: "STRING",
        value: "driveUnitName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.Origin.Headers.startDate"),
        type: "STRING",
        value: "start",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.workfrontIn"),
        type: "STRING",
        value: "harvestFrontIn",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.workfrontOut"),
        type: "STRING",
        value: "harvestFrontOut",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.sugarmilIn"),
        type: "STRING",
        value: "sugarmilIn",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.Origin.Headers.endDate"),
        type: "STRING",
        value: "emptyIn",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.boxQuantity"),
        type: "STRING",
        value: "boxQuantity",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.harvestedArea"),
        type: "STRING",
        value: "harvestedAreaString",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.weight"),
        type: "STRING",
        value: "weight",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.TCH"),
        type: "STRING",
        value: "TCH",
    }
];
export const FRONT_HEADERS = [
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckHistoricalReport.HEADERS.HARVEST_FRONT"),
        type: "STRING",
        value: "harvestFrontName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("harvesters.reports.currentStatus.headers.driveUnit"),
        type: "STRING",
        value: "driveUnitName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdpReport.Headers.trips"),
        type: "STRING",
        value: "travelCounts",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.boxQuantity"),
        type: "STRING",
        value: "boxQuantity",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.workingHours"),
        type: "STRING",
        value: "workingHours",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.averageHarvestedArea"),
        type: "STRING",
        value: "averageHarvestedAreaString",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.totalHarvestedArea"),
        type: "STRING",
        value: "totalHarvestedAreaString",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.averageWeight"),
        type: "STRING",
        value: "averageWeight",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.totalWeight"),
        type: "STRING",
        value: "totalWeight",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.averageTCH"),
        type: "STRING",
        value: "averageTCH",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.totalTCH"),
        type: "STRING",
        value: "totalTCH",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.Origin.Headers.timeInEquipment"),
        type: "STRING",
        value: "timeInEquipment",
    },
]

export const DETAIL_HEADERS = [
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.HEADERS.FLEET"),
        type: "STRING",
        value: "fleetName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.driver"),
        type: "STRING",
        value: "driverName",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckCurrentStateReport.origin.headers.truckName"),
        type: "STRING",
        value: "truckName",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("caneEquipments.title"),
        type: "STRING",
        value: "caneEquipmentName",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckHistoricalReport.HEADERS.HARVEST_FRONT"),
        type: "STRING",
        value: "harvestFrontName",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdpReport.Headers.harvesterName"),
        type: "STRING",
        value: "harvesterName",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdpReport.Headers.tractorName"),
        type: "STRING",
        value: "tractorName",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("harvesters.reports.currentStatus.headers.driveUnit"),
        type: "STRING",
        value: "driveUnitName",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.harvestedArea"),
        type: "STRING",
        value: "harvestedAreaString",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.Origin.Headers.startDate"),
        type: "STRING",
        value: "start",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.workfrontIn"),
        type: "STRING",
        value: "harvestFrontIn",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.Origin.Headers.tractorDischargeDate"),
        type: "STRING",
        value: "dischargeTrackTimestamp",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.workfrontOut"),
        type: "STRING",
        value: "harvestFrontOut",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("cdcReport.headers.sugarmilIn"),
        type: "STRING",
        value: "sugarMillIn",
    }, {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.Origin.Headers.endDate"),
        type: "STRING",
        value: "emptyIn",
    },
    {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("truckSummaryReport.Origin.Headers.timeInEquipment"),
        type: "STRING",
        value: "secondsInEquipment",
    },
]