import cdcReportApi from '@/api/cdcReport.api'
import driveUnitApi from '@/api/drive-unit.api'
import fleetApi from '@/api/fleet.api'
import reportStorageApi from '@/api/report-storage.api'
import thingApi from '@/api/thing.api'
import autoReportService from '@/business/autoReportService'
import cdcReportService from '@/business/cdcReportService'
import selectorService from '@/business/selectorService'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import CDCDetail from '@/components/traceability/cdc-report/details/CDCDetailReport.vue'
import { SnackbarStyle } from '@/constants/constants'
import i18n from '@/i18n'
import { filterRulesMapped } from '@/tools/filterRules'
import {
    copyJson,
    getChildrenFromList,
    getDateTimeRange,
    ISODateFromUnixTimestamp,
    selectorDateTimeValidation
} from '@/tools/functions'
import { CustomTimeRangeTypes, TimeRanges } from '@colven/common-domain-lib/lib'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { EQUIPMENT_HEADERS, FRONT_HEADERS, REPORT_TABS, TRAVEL_HEADERS, UM_HEADERS } from './cdcConstants'


export default {
    name: 'CdcReport',
    components: {
        BreadcrumbComponent,
        SelectorComponent,
        CDCDetail,
    },
    model: {
        prop: 'model'
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        autoReportInteval: null,
        tableData: [],
        tableHeaders: [],
        tableFilterRules: {},
        // headers
        umHeaders: copyJson(UM_HEADERS),
        frontHeaders: copyJson(FRONT_HEADERS),
        equipmentHeaders: copyJson(EQUIPMENT_HEADERS),
        travelHeaders: copyJson(TRAVEL_HEADERS),
        // datos de las tablas
        umData: [],
        equipmentData: [],
        travelData: [],
        frontData: [],
        // key de los datos de la tabla
        tableDataKey: 'id',
        // id de los tabs
        tabId: 'tab-report-um',
        intervalId: null,
        loadingSpinner: true,
        loadingTable: false,
        disableRefreshTable: true,
        timeStampFrom: null,
        timeStampTo: null,
        tabsCDCReport: [
            {
                id: 'tab-cdc-summary',
                name: i18n.t('summary')
            }
        ],
        selectorModel: selectorService.getSelectorModel(false),
        selectorFilters: {
            truckFilter: {
                id: 'trucks',
                name: i18n.t('trucks.title'),
                show: false,
                showDialog: false,
                disabled: false,
                data: [],
                selectedData: [],
                singleSelect: false,
                selectAction: undefined
            },
            caneEquipmentFilter: {
                id: 'caneEquipments',
                name: i18n.t('caneEquipments.title'),
                show: false,
                showDialog: false,
                disabled: false,
                data: [],
                selectedData: [],
                singleSelect: false,
                selectAction: undefined
            },
            harvesterFilter: {
                id: 'harvesters',
                name: i18n.t('selectorTitles.harvesters'),
                show: false,
                showDialog: false,
                disabled: false,
                data: [],
                selectedData: [],
                singleSelect: false,
                selectAction: undefined
            },
            harvestTractorFilter: {
                id: 'harvestTractors',
                name: i18n.t('selectorTitles.harvestTractor'),
                show: false,
                showDialog: false,
                disabled: false,
                data: [],
                selectedData: [],
                singleSelect: false,
                selectAction: undefined
            },
            driveUnitFilter: {
                id: 'driveUnit',
                name: i18n.t('driveUnits.title'),
                show: false,
                showDialog: false,
                disabled: false,
                data: [],
                selectedData: [],
                singleSelect: false,
                selectAction: undefined
            },
            workFrontFilter: {
                id: 'workFront',
                name: i18n.t('machineSummaryReport.workFronts'),
                show: false,
                showDialog: false,
                disabled: false,
                data: [],
                selectedData: [],
                singleSelect: false,
                selectAction: undefined
            }
        },
        timeFormat: null,
        showSelector: true,
        disableSelector: false,
        tabsCDCSummaryReport: [
            {
                id: 'tab-cdc-summary',
                name: i18n.t('summary')
            }
        ],
        tabSelectedCDCReport: 'tab-cdc-summary',
        breadcrumbButtomsCDCReport: [],
        reportDetailModel: {
            loadingSpinner: false,
            details: []
        },
        // backup de selectores
        filterSwitch: true,
        sectors: [],
        activities: [],
        types: [],
        things: [],
        driveUnits: [],
        dateAndTimeRange: TimeRanges.LAST_HOUR,
        dateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
        sinceDate: null,
        sinceTime: null,
        toDate: null,
        toTime: null,
        rowSelected: null,
        detailsData: [],
        detailsKey: (Math.random() + 1).toString(36).substring(7)
    }),
    async mounted() {
        // Setea el lenguaje de los componentes de Vuetify
        this.$vuetify.lang.current = this.$i18n.locale
        // Inicializo los datos del selector
        await this.initializeSelector()
        // carga inicial del reporte
        const reportKey = this.$route.query.key
        this.initialReportLoad(reportKey)
    },
    beforeDestroy() {
        clearInterval(this.autoReportInteval);
        this.setBreadcrumbDetails(null);
        this.commitVisible({ visible: false })
    },
    created() {
        // Agrego el botón del breadcrumb para mostrar/ocultar el selector
        this.breadcrumbButtomsCDCReport.push(
            {
                id: 'setting-btn',
                icon: 'settings',
                show: () => { return true },
                disable: this.disableSelectorButton.bind(this),
                function: this.selector.bind(this)
            })
    },
    computed: {
        ...mapGetters('breadcrumb', {
            getDetails: 'getDetails'
        })
    },
    methods: {
        ...mapActions({
            'showSnackbar': 'snackbar/showSnackbar',
            'closeSnackbar': 'snackbar/closeSnackbar',
            'setDetails': 'breadcrumb/setDetails',
        }),
        ...mapMutations({
            'setBreadcrumbDetails': 'breadcrumb/commitDetails'
        }),
        ...mapMutations('reportDefer', {
            showReportDefer: 'showReportDefer',
            closeReportDefer: 'closeReportDefer',
            commitVisible: 'commitVisible'
        }),
        ...mapMutations('app', {
            setEntitySelectorItems: 'setEntitySelectorItems',
            setEntityConfiguration: 'setEntityConfiguration',
            resetEntitySelector: 'resetEntitySelector',
            setSelectedItem: 'setSelectedItem'
        }),
        /**
         * Para el botón del breadcrumb
         */
        selector() {
            this.showSelector = !this.showSelector
        },
        /**
         * Cambia de tab (breadcrumb)
         * @param {*} id
         */
        tabSelected(id) {
            if (id === 'tab-report-um') {
                this.commitVisible({ visible: false })
                this.reportDetailModel.loadingSpinner = false
                this.loadingSpinner = true
                // Se resetea el breadcrumb al volver al Resumen
                this.tabsCDCSummaryReport = [
                    {
                        id: 'tab-cdc-summary',
                        name: i18n.t('summary')
                    }
                ]
                if (this.$store.state.app.entitySelectorItems && this.$store.state.app.entitySelectorItems.length > 0) {
                    this.$store.state.app.entitySelectorItems = []
                }
            } else if (id === 'tab-cdc-detail') {
                this.commitVisible({ visible: false })
                this.reportDetailModel.loadingSpinner = true
                this.loadingSpinner = false
            }
            this.tabSelectedCurrentStatus = id
        },
        /**
         * Para deshabilitar el botón del breadcrumb
         */
        disableSelectorButton() {
            return this.disableSelector
        },
        /**
         * Incializador del selector
         */
        async initializeSelector() {
            const [trucksData, caneEquipmentsData, harvestersData, harvesterTractorsData, harvestFrontsData] = await Promise.all([
                fleetApi.getNested(),
                thingApi.getAllThingsByType('CANE_EQUIPMENT'),
                thingApi.getAllThingsByType('HARVESTER'),
                thingApi.getAllThingsByType('HARVEST_TRACTOR'),
                thingApi.getValidHarvestFrontsNested()

            ]);
            const trucks = trucksData.data;
            const caneEquipments = caneEquipmentsData.data.map(caneEq => {
                caneEq.id = caneEq._id;
                delete caneEq._id;
                return caneEq;
            });
            const harvesters = harvestersData.data.map(harvester => {
                harvester.id = harvester._id;
                delete harvester._id;
                return harvester;
            });
            const harvesterTractors = harvesterTractorsData.data.map(harvesTractor => {
                harvesTractor.id = harvesTractor._id;
                delete harvesTractor._id;
                return harvesTractor;
            });
            const harvestFronts = harvestFrontsData.data;

            this.selectorFilters.truckFilter.data.cleanAndUpdate(trucks);
            this.selectorFilters.caneEquipmentFilter.data.cleanAndUpdate(caneEquipments);
            this.selectorFilters.harvesterFilter.data.cleanAndUpdate(harvesters);
            this.selectorFilters.harvestTractorFilter.data.cleanAndUpdate(harvesterTractors);
            this.selectorFilters.workFrontFilter.data.cleanAndUpdate(harvestFronts);

            // datos para el selector de unidades de manejo
            const driveUnits = await driveUnitApi.getStructureByEnterprise()
            const hasDriveUnits = this.enterpriseHasDriveUnits(driveUnits)
            if (hasDriveUnits) {
                this.selectorFilters.driveUnitFilter.data.cleanAndUpdate(driveUnits.structure)
            } else {
                this.selectorFilters.driveUnitFilter.data.cleanAndUpdate([])
                this.selectorFilters.driveUnitFilter.disabled = true
                this.selectorModel.disableGetReportButton = true
                this.showSnackbar({ visible: true, text: this.$t('driveUnits.noData'), timeout: 8000, style: SnackbarStyle.ERROR })
            }

            Object.values(this.selectorFilters)

            this.selectorModel.filters.push(...Object.values(this.selectorFilters));
        },
        /**
        * Cargar reporte diferido
        * @param {*} reportKey
        */
        async getData(reportKey) {
            if (reportKey) {
                try {
                    this.loadingTable = true;
                    this.showSelector = false
                    this.disableSelector = false
                    this.disableRefreshTable = true
                    const response = await reportStorageApi.getReport(reportKey);
                    const { data, filters } = response.data
                    const parsedResult = this.setNamesForResultData(data)
                    this.processData(parsedResult);
                    // datos de los filtros
                    this.setFilterData(filters)
                    this.tabClicked(this.tabId, false);
                } finally {
                    this.loadingTable = false;
                }
            } else {
                this.showSelector = true
            }
        },
        /**
         * Setear las reglas de filtrado
         * @param {*} headers
         */
        setTableFilterRules(headers) {
            this.tableFilterRules = {}
            headers.forEach(header => {
                this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
            })
        },
        /**
         * Cambiar de tab, ir al detalle de la máquina clickeada
         * @param {*} data
         */
        clickRow(data) {
            if (this.tabId === REPORT_TABS.DRIVE_UNIT) {
                const newDetails = this.detailsData.filter((detail) => { return detail.driveUnitId === data.driveUnitId });
                this.reportDetailModel.details.cleanAndUpdate(newDetails);
            } else if (this.tabId === REPORT_TABS.EQUIPMENT) {
                const newDetails = this.detailsData.filter((detail) => {
                    return detail.caneEquipmentId === data.caneEquipmentId && detail.driveUnitId === data.driveUnitId && detail.harvestFrontId === data.harvestFrontId
                });
                this.reportDetailModel.details.cleanAndUpdate(newDetails);
            } else if (this.tabId === REPORT_TABS.HARVEST_FRONT) {
                const newDetails = this.detailsData.filter((detail) => {
                    return detail.harvestFrontId === data.harvestFrontId && detail.driveUnitId === data.driveUnitId
                });
                this.reportDetailModel.details.cleanAndUpdate(newDetails);
            } else if (this.tabId === REPORT_TABS.TRIP) {
                const newDetails = this.detailsData.filter((detail) => {
                    return detail.caneEquipmentId === data.caneEquipmentId && detail.startTimestamp === data.startTimestamp
                });
                this.reportDetailModel.details.cleanAndUpdate(newDetails);
            }
            this.detailsKey = (Math.random() + 1).toString(36).substring(7);
            // this.setEntityConfiguration({ name: this.$t('harvesters.title'), value: 'driveUnitId', text: 'driveUnitName' })
            // this.commitVisible({ visible: false })
            // this.reportDetailModel.loadingSpinner = true
            // const driveUnits = []
            // getChildrenFromList(this.driveUnits, driveUnits)
            // this.loadingSpinner = false
            if (this.tabsCDCReport.filter(tab => tab.id === 'tab-cdc-detail').length === 0) {
                this.tabsCDCReport.push({
                    id: 'tab-cdc-detail',
                    name: i18n.t('detail')
                })
            }
            this.tabSelectedCDCReport = 'tab-cdc-detail'
        },
        /**
         * Acción del botón para actualizar los datos de la tabla
         */
        async refreshTable() {
        },
        getDriveunitFieldFilter(list, field) {
            let result = [];
            if (list && list.length > 0) {
                for (const element of list) {
                    if (element.type === 'DRIVE_UNIT') {
                        result.push(element[field]);
                    }
                    if (element.children && element.children.length > 0) {
                        const driveUnits = this.getDriveunitFieldFilter(element.children, field);
                        result = result.concat(driveUnits);
                    }
                }
            }
            return result;
        },
        /**
         * Obtener reporte
         * @param {*} eventData
         */
        async getReport(eventData) {
            this.showSelector = false
            clearInterval(this.autoReportInteval);
            const originalFilters = {
                dateAndTimeRange: this.selectorModel.selectedDateAndTimeRange,
                dateAndTimeRangeCustomType: this.selectorModel.selectedDateAndTimeRangeCustomType,
                timeFormat: this.selectorModel.selectedTimeFormat,
                sinceDate: this.selectorModel.sinceDate,
                sinceTime: this.selectorModel.sinceTime,
                toDate: this.selectorModel.toDate,
                toTime: this.selectorModel.toTime,
                caneEquipment: this.selectorFilters.caneEquipmentFilter.selectedData,
                driveUnit: this.selectorFilters.driveUnitFilter.selectedData,
                harvester: this.selectorFilters.harvesterFilter.selectedData,
                harvestTractor: this.selectorFilters.harvestTractorFilter.selectedData,
                workFront: this.selectorFilters.workFrontFilter.selectedData,
                truck: this.selectorFilters.truckFilter.selectedData
            };
            // guardo los datos elegidos en los selectores
            this.dateAndTimeRange = eventData.dateAndTimeRange
            this.dateAndTimeRangeCustomType = eventData.dateAndTimeRangeCustomType
            this.sinceDate = eventData.sinceDate
            this.sinceTime = eventData.sinceTime
            this.toDate = eventData.toDate
            this.toTime = eventData.toTime

            const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
                this.sinceDate, this.sinceTime, this.toDate, this.toTime)
            this.timeStampFrom = conversion.tFrom
            this.timeStampTo = conversion.tTo

            let caneEquipments;
            if (this.selectorFilters.caneEquipmentFilter.selectedData.length > 0) {
                caneEquipments = this.selectorFilters.caneEquipmentFilter.selectedData.map(caneEquipment => caneEquipment.id);
            }
            else {
                caneEquipments = this.selectorFilters.caneEquipmentFilter.data.map(caneEquipment => caneEquipment.id);
            }

            const truckIds = [];
            getChildrenFromList(this.selectorFilters.truckFilter.selectedData, truckIds)
            const filters = {
                truckIds: truckIds.map(truck => truck.id),
                caneEquipments,
                harvesterIds: this.selectorFilters.harvesterFilter.selectedData.map(harvester => harvester.id),
                tractorIds: this.selectorFilters.harvestTractorFilter.selectedData.map(harvestTractor => harvestTractor.id),
                driveUnitsIds: this.getDriveunitFieldFilter(this.selectorFilters.driveUnitFilter.selectedData, 'id'),
                harvestFrontIds: this.selectorFilters.workFrontFilter.selectedData.map(workFront => workFront.id),
                from: this.timeStampFrom,
                to: this.timeStampTo,
                originalFilters
            }
            // if (this.dateAndTimeRange === 'custom') {
            //     this.commitVisible({ visible: true })
            //     await cdcReportApi.getDataDeferred(filters);
            //     this.showReportDefer({ updateFunction: this.getData.bind(this) })
            //     return;
            // }
            if (this.selectorModel.autoReport && this.selectorModel.autoReportMinutes && this.dateAndTimeRange !== 'custom') {
                let result;
                this.loadingTable = true;
                result = await cdcReportApi.getData(filters);
                const parsedResult = this.setNamesForResultData(result.data)
                this.processData(parsedResult);
                this.setFilterData(filters);
                this.tabClicked(this.tabId, false);
                this.timeFormat = eventData.dateAndTimeFormat
                this.reportDetailModel.from = this.timeStampFrom
                this.reportDetailModel.to = this.timeStampTo
                this.autoReportInteval = setInterval(async () => {
                    const result = await cdcReportApi.getData(filters);
                    const parsedResult = this.setNamesForResultData(result.data)
                    this.updateDate(filters);
                    this.setFilterData(filters);
                    this.processData(parsedResult);
                    this.tabClicked(this.tabId, false);
                }, this.selectorModel.autoReportMinutes * 60 * 1000);
            } else {
                this.commitVisible({ visible: true })
                await cdcReportApi.getDataDeferred(filters);
                this.showReportDefer({ updateFunction: this.getData.bind(this) })
            }
            this.loadingTable = false;
        },
        updateDate(filters) {
            const conversion = getDateTimeRange(filters.originalFilters.dateAndTimeRange, filters.originalFilters.dateAndTimeRangeCustomType,
                filters.originalFilters.sinceDate, filters.originalFilters.sinceTime, filters.originalFilters.toDate, filters.originalFilters.toTime)
            filters.from = conversion.tFrom;
            filters.to = conversion.tTo;
        },
        processData(dataResult) {
            const result = cdcReportService.processReportData(dataResult)
            this.umData.cleanAndUpdate(result.umResult);
            this.travelData.cleanAndUpdate(result.travelResult);
            this.equipmentData.cleanAndUpdate(result.equipmentResult);
            this.frontData.cleanAndUpdate(result.frontResult);
            this.reportDetailModel.details.cleanAndUpdate(result.details);
            this.detailsData.cleanAndUpdate(result.details);
            this.detailsKey = (Math.random() + 1).toString(36).substring(7)

        },
        setNamesForResultData(resultData) {
            return resultData.map(item => {
                const caneEquipment = this.selectorFilters.caneEquipmentFilter.data.find(ce => ce.id === item.caneEquipmentId);

                const tractor = this.selectorFilters.harvestTractorFilter.data.find(ce => ce.id === item.tractorId);
                const harvestFront = this.selectorFilters.workFrontFilter.data.find(ce => ce.id === item.harvestFrontId);
                const harvester = this.selectorFilters.harvesterFilter.data.find(ce => ce.id === item.harvesterId);

                let truck;
                let fleetName;
                for (const flota of this.selectorFilters.truckFilter.data) {
                    let result = null;
                    if (flota.children) {
                        result = flota.children.find(truck => truck.id === item.truckId);
                    } else {
                        if (flota.id === item.truckId) {
                            result = flota;
                        }
                    }
                    if (result) {
                        truck = result;
                        fleetName = flota.name;
                        break;
                    }
                }
                let driveUnit;
                for (const farm of this.selectorFilters.driveUnitFilter.data) {
                    for (const ecoUnit of farm.children) {
                        const du = ecoUnit.children.find(driveUnit => driveUnit.id === item.driveUnitId);
                        if (du) {
                            driveUnit = du;
                            break;
                        }
                    }
                }

                const finalResult = {};

                if (caneEquipment) finalResult.caneEquipmentName = caneEquipment.name;
                if (truck) finalResult.truckName = truck.name;
                if (tractor) finalResult.tractorName = tractor.name;
                if (harvestFront) finalResult.harvestFrontName = harvestFront.name;
                if (driveUnit) finalResult.driveUnitName = driveUnit.name.slice(-6);
                if (harvester) finalResult.harvesterName = harvester.name;
                if (fleetName) finalResult.fleetName = fleetName;
                return { ...item, ...finalResult };
            });
        },
        /**
         * Detalles del reporte
         */
        setReportDetails(filterData) {
            const detailData = {
                from: filterData.from,
                to: filterData.to
            }

            const dataStructure = [
                { detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE' },
                { detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE' }
            ]
            if (filterData.driveUnit.length) {
                detailData.driveUnits = this.getDriveunitFieldFilter(filterData.driveUnit, 'name');
                dataStructure.push({
                    detailDataProp: 'driveUnits',
                    propTitle: this.$t('cdcReport.filters.driveUnits'),
                    propType: 'LIST',
                    propRefs: { value: 'id', text: 'name' }
                })
            }
            if (filterData.workFront.length) {
                detailData.workFront = filterData.workFront.map(workFront => workFront.name),
                    dataStructure.push({
                        detailDataProp: 'workFront',
                        propTitle: this.$t('cdcReport.filters.workFront'),
                        propType: 'LIST',
                        propRefs: { value: 'id', text: 'name' }
                    })
            }
            if (filterData.harvester.length) {
                detailData.harvester = filterData.harvester.map(harvester => harvester.name),
                    dataStructure.push({
                        detailDataProp: 'harvester',
                        propTitle: this.$t('cdcReport.filters.harvester'),
                        propType: 'LIST',
                        propRefs: { value: 'id', text: 'name' }
                    })
            }
            if (filterData.harvestTractor.length > 0) {
                detailData.harvestTractor = filterData.harvestTractor.map(harvestTractor => harvestTractor.name),
                    dataStructure.push({
                        detailDataProp: 'harvestTractor',
                        propTitle: this.$t('cdcReport.filters.harvestTractor'),
                        propType: 'LIST',
                        propRefs: { value: 'id', text: 'name' }
                    })
            }
            if (filterData.truck.length > 0) {
                detailData.truck = filterData.truck.map(truck => truck.name),
                    dataStructure.push({
                        detailDataProp: 'truck',
                        propTitle: this.$t('cdcReport.filters.truck'),
                        propType: 'LIST',
                        propRefs: { value: 'id', text: 'name' }
                    })
            }
            if (filterData.caneEquipment.length > 0) {
                detailData.caneEquipment = filterData.caneEquipment.map(caneEquipment => caneEquipment.name),
                    dataStructure.push({
                        detailDataProp: 'caneEquipment',
                        propTitle: this.$t('cdcReport.filters.caneEquipment'),
                        propType: 'LIST',
                        propRefs: { value: 'id', text: 'name' }
                    })
            }
            this.setDetails({
                detailData,
                dataStructure
            })
        },
        /**
         * Cargar los datos del selector
         * @param filters
         */
        setFilterData(filters) {
            const filterData = filters.originalFilters;
            filterData.from = filters.from;
            filterData.to = filters.to;
            this.selectorFilters.driveUnitFilter.selectedData.cleanAndUpdate(filterData.driveUnit ? filterData.driveUnit.slice() : [])
            this.selectorFilters.workFrontFilter.selectedData.cleanAndUpdate(filterData.workFront ? filterData.workFront.slice() : [])
            this.selectorFilters.caneEquipmentFilter.selectedData.cleanAndUpdate(filterData.caneEquipment ? filterData.caneEquipment.slice() : [])
            this.selectorFilters.harvesterFilter.selectedData.cleanAndUpdate(filterData.harvester ? filterData.harvester.slice() : [])
            this.selectorFilters.harvestTractorFilter.selectedData.cleanAndUpdate(filterData.harvestTractor ? filterData.harvestTractor.slice() : [])
            this.selectorFilters.truckFilter.selectedData.cleanAndUpdate(filterData.truck ? filterData.truck.slice() : [])
            this.selectorModel.selectedDateAndTimeRange = filterData.dateAndTimeRange
            this.selectorModel.selectedDateAndTimeRangeCustomType = filterData.dateAndTimeRangeCustomType
            this.selectorModel.sinceDate = filterData.sinceDate
            this.selectorModel.sinceTime = filterData.sinceTime
            this.selectorModel.toDate = filterData.toDate
            this.selectorModel.toTime = filterData.toTime
            this.selectorModel.selectedTimeFormat = filterData.timeFormat
            // sectores
            if (filterData.sectors) {
                this.sectorFilter.selectedData = filterData.sectors
            }
            // tipos
            this.types.cleanAndUpdate(filterData.types ? filterData.types.slice() : [])
            // cosas
            this.things.cleanAndUpdate(filterData.things ? filterData.things.slice() : [])
            // unidades de manejo
            this.driveUnits.cleanAndUpdate(filterData.driveUnits ? filterData.driveUnits.slice() : [])
            
            // selectores de rangos de tiempo
            this.timeFormat = filterData.timeFormat
            this.dateAndTimeRange = filterData.dateTimeRange
            this.dateAndTimeRangeCustomType = filterData.customDateTimeRangeType
            this.sinceDate = filterData.sinceDate
            this.sinceTime = filterData.sinceTime
            this.toDate = filterData.toDate
            this.toTime = filterData.toTime
            this.setCustomDateAndTimeFilter(filterData)
            this.setReportDetails(filterData)
            this.validateSelector()
        },
        setCustomDateAndTimeFilter(filterData) {
            if (filterData.timestampFrom && filterData.timestampTo) {
                const sinceDateData = ISODateFromUnixTimestamp(filterData.timestampFrom, true)
                const toDateData = ISODateFromUnixTimestamp(filterData.timestampTo, true)

                this.selectorModel.sinceDate = sinceDateData.date
                this.selectorModel.toDate = toDateData.date

                if (this.selectorModel.selectedDateAndTimeRange !== TimeRanges.CUSTOM) {
                    this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
                    this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
                    this.selectorModel.sinceTime = sinceDateData.time
                    this.selectorModel.toTime = toDateData.time
                } else if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM &&
                    this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
                    this.selectorModel.sinceTime = null
                    this.selectorModel.toTime = null
                } else {
                    this.selectorModel.sinceTime = sinceDateData.time
                    this.selectorModel.toTime = toDateData.time
                }
            }
        },
        disableWorkFrontFilter() {
            if (this.driveUnitFilter.selectedData.length > 0) {
                this.workFrontFilter.disabled = true
                this.workFrontFilter.selectedData.clean()
            } else if (this.typeFilter.selectedData.length > 0) {
                this.workFrontFilter.disabled = false
            }
        },
        disableDriveUnitFilter() {
            if (this.workFrontFilter.selectedData.length > 0) {
                this.driveUnitFilter.disabled = true
                this.driveUnitFilter.selectedData.clean()
            } else {
                this.driveUnitFilter.disabled = false
            }
        },
        validateSelector() {
            const that = this
            Vue.nextTick(function () {
                const customTimeValidation = selectorDateTimeValidation(that.selectorModel.selectedDateAndTimeRange,
                    that.selectorModel.selectedDateAndTimeRangeCustomType, that.selectorModel.customDateTimeValidForm,
                    that.selectorModel.sinceDate, that.selectorModel.sinceTime, that.selectorModel.toDate, that.selectorModel.toTime)
                const timeFormatSelected = that.selectorModel.selectedTimeFormat
                that.selectorModel.disableGetReportButton = customTimeValidation || !timeFormatSelected
            })
        },
        /**
         * Validar que una empresa tenga unidades de manejos
         */
        enterpriseHasDriveUnits(driveUnits) {
            let hasDriveUnits = false
            if (driveUnits && driveUnits.structure) {
                const structureDriveUnits = []
                getChildrenFromList(driveUnits.structure, structureDriveUnits)
                if (structureDriveUnits.length > 0) {
                    hasDriveUnits = true
                }
            }
            return hasDriveUnits
        },
        /**
         * Actualizar los datos de los gráficos del reporte
         * @param {*} data
         */
        setChartData(data) {
            this.showPlantationAvailableTimeChart = data.availableTimeCharts.includePlantationAvailableTime

            // gráficos de tiempo en cada estado
            this.timeByState.thingCharts.cleanAndUpdate(data.timeByStateCharts.thingCharts)
            this.timeByState.things.cleanAndUpdate(data.timeByStateCharts.things)
            this.timeByState.driverCharts.cleanAndUpdate(data.timeByStateCharts.driverCharts)
            this.timeByState.drivers.cleanAndUpdate(data.timeByStateCharts.drivers)
            this.timeByState.workFrontCharts.cleanAndUpdate(data.timeByStateCharts.workFrontCharts)
            this.timeByState.workFronts.cleanAndUpdate(data.timeByStateCharts.workFronts)

            // gráficos de tiempo disponible
            this.availableTime.thingCharts.cleanAndUpdate(data.availableTimeCharts.thingCharts)
            this.availableTime.things.cleanAndUpdate(data.availableTimeCharts.things)
            this.availableTime.driverCharts.cleanAndUpdate(data.availableTimeCharts.driverCharts)
            this.availableTime.drivers.cleanAndUpdate(data.availableTimeCharts.drivers)
            this.availableTime.workFrontCharts.cleanAndUpdate(data.availableTimeCharts.workFrontCharts)
            this.availableTime.workFronts.cleanAndUpdate(data.availableTimeCharts.workFronts)

            // gráficos de tiempo disponible de plantación
            if (data.availableTimeCharts.includePlantationAvailableTime) {
                this.plantationAvailableTime.thingCharts.cleanAndUpdate(data.availableTimeCharts.plantationThingCharts)
                this.plantationAvailableTime.things.cleanAndUpdate(data.availableTimeCharts.plantationThings)
                this.plantationAvailableTime.driverCharts.cleanAndUpdate(data.availableTimeCharts.plantationDriverCharts)
                this.plantationAvailableTime.drivers.cleanAndUpdate(data.availableTimeCharts.plantationDrivers)
                this.plantationAvailableTime.workFrontCharts.cleanAndUpdate(data.availableTimeCharts.plantationWorkFrontCharts)
                this.plantationAvailableTime.workFronts.cleanAndUpdate(data.availableTimeCharts.plantationWorkFronts)
            } else {
                this.plantationAvailableTime = {
                    thingCharts: [],
                    things: [],
                    driverCharts: [],
                    drivers: [],
                    workFrontCharts: [],
                    workFronts: []
                }
            }
        },
        /**
         * Inicializar gráficos
         */
        initCharts() {
            const that = this
            Vue.nextTick(function () {
                that.timeByStateDonutChartContainerModel.initialize = true
                that.availableTimeDonutChartContainerModel.initialize = true
                that.plantationAvailableTimeDonutChartContainerModel.initialize = true
            })
        },
        /**
         * Setear los datos y headers para las tablas
         * @param {*} data
         * @param {*} update
         */
        setTableData(data, update = false) {
            // headers
            this.frontHeaders.cleanAndUpdate(data.frontHeaders)
            this.umHeaders.cleanAndUpdate(data.umHeaders)
            this.equipmentHeaders.cleanAndUpdate(data.equipmentHeaders)
            // datos
            this.frontData.cleanAndUpdate(data.things)
            this.equipmentData.cleanAndUpdate(data.drivers)
            this.umData.cleanAndUpdate(data.workFronts)

            // cargo los datos del tab actual
            this.tabClicked(this.tabId, update)
        },
        /**
         * Cambiar los datos y los headers de la tabla cuando se cambia de tab
         * @param {*} id
         * @param {*} update
         */
        tabClicked(id, update = false) {
            this.tabId = id
            switch (id) {
                // TODO: revisar metodos que obtienen headers y datos
                case 'tab-report-um':
                    this.tableData.cleanAndUpdate(this.umData)
                    if (!update) {
                        this.tableHeaders.cleanAndUpdate(this.umHeaders)
                        this.setTableFilterRules(this.umHeaders)
                    }
                    break
                case 'tab-report-equipment':
                    this.tableData.cleanAndUpdate(this.equipmentData)
                    if (!update) {
                        this.tableHeaders.cleanAndUpdate(this.equipmentHeaders)
                        this.setTableFilterRules(this.equipmentHeaders)
                    }
                    break
                case 'tab-report-travel':
                    this.tableData.cleanAndUpdate(this.travelData)
                    if (!update) {
                        this.tableHeaders.cleanAndUpdate(this.travelHeaders)
                        this.setTableFilterRules(this.travelHeaders)
                    }
                    break
                case 'tab-report-front':
                    this.tableData.cleanAndUpdate(this.frontData)
                    if (!update) {
                        this.tableHeaders.cleanAndUpdate(this.frontHeaders)
                        this.setTableFilterRules(this.frontHeaders)
                    }
                    break
            }
        },
        /**
         * Carga inicial del reporte, este método se ejecuta en el hook mounted para decidir
         * si el reporte debe cargarse de la base de datos (diferido) o es un autorreporte
         */
        initialReportLoad() {
            // obtener los datos de la url
            const queryValidation = autoReportService.autoReportRouteQueryValidation(this.$route.query)
            switch (queryValidation.type) {
                // diferido
                case 'DEFERRED':
                    this.getData(queryValidation.key)
                    break
                // reporte en blanco
                case 'EMPTY':
                    this.showSelector = true
                    break
                default:
                    this.showSelector = true
                    break
            }
        },
        showDetails(data) {
            console.log(data);
        }
    },
    watch: {
        selectorModel: {
            handler: function () {
                this.validateSelector()
            },
            deep: true
        }
    }
}
