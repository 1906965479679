import service from '@/middleware'

const cdcReportApi = {
    async getData(filters) {
        return service.http.post(`/api/cdcReport`, filters)
    },
    async getDataDeferred(filters) {
        return service.http.post(`/api/cdcReport/deferred`, filters)
    }
}

export default cdcReportApi