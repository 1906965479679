import { getTimeOffset } from '@/tools/functions'
import { ReportConversionUtil } from '@colven/common-domain-lib/lib'

const processReportData = (dataResult) => {

    const timeOffset = getTimeOffset()

    const umMap = {}
    const umTravelStart = {};
    const equipmentMap = {}
    const equipmentTravelStart = {};
    const travelsMap = {}
    const frontMap = {}
    const frontTravelStart = {}
    const details = []
    dataResult = filterRepeated(dataResult);
    
    //Cálculo de los segundos trabajados por frente
    let workingSecondsMap= {}
    dataResult.forEach(item=>{ 
        if(!workingSecondsMap[item.harvestFrontId]){
            workingSecondsMap[item.harvestFrontId] = 0
        }
        if(item.workingSeconds){ 
         workingSecondsMap[item.harvestFrontId] += item.workingSeconds;
        }else{
            workingSecondsMap[item.harvestFrontId] = 0;
        }
    })

    dataResult.forEach(item => {
        const tripStartDate = ReportConversionUtil.applyTimezone(item.start, timeOffset)
        item.startDate = tripStartDate.dateString + ' ' + tripStartDate.timeString

        const harvestFrontInDate = ReportConversionUtil.applyTimezone(item.harvestFrontTimestamp, timeOffset)
        item.harvestFrontInDate = item.harvestFrontTimestamp ? (harvestFrontInDate.dateString + ' ' + harvestFrontInDate.timeString) : ''

        const harvestFrontOutDate = ReportConversionUtil.applyTimezone(item.disengagement, timeOffset)
        item.harvestFrontOutDate = item.disengagement ? (harvestFrontOutDate.dateString + ' ' + harvestFrontOutDate.timeString) : ''

        const ingInDate = ReportConversionUtil.applyTimezone(item.truckInginTimestamp, timeOffset)
        item.ingInDate = item.truckInginTimestamp ? (ingInDate.dateString + ' ' + ingInDate.timeString) : ''

        const emptyInDate = ReportConversionUtil.applyTimezone(item.trapicheOutTimestamp, timeOffset)
        item.emptyInDate = item.trapicheOutTimestamp ? (emptyInDate.dateString + ' ' + emptyInDate.timeString) : ''

        const dischargeDate = ReportConversionUtil.applyTimezone(item.dischargeTrackTimestamp, timeOffset)
        item.dischargeDate = item.dischargeTrackTimestamp ? (dischargeDate.dateString + ' ' + dischargeDate.timeString) : ''
        if (item.disengagement && item.trapicheOutTimestamp) {
            const secondsInEquipment = item.trapicheOutTimestamp - item.disengagement;
            item.timeInEquipment = ReportConversionUtil.secondsToStringFormatted(secondsInEquipment, "hh:mm:ss");
        }

        /**-------------- UNIDADES DE MANEJO------------ */ // y frente
        let currentDriveUnit = umMap[`${item.driveUnitId}-${item.harvestFrontId}`];
        let currentDriveUnitTravelStartRecords = umTravelStart[`${item.driveUnitId}-${item.harvestFrontId}`];
        if (currentDriveUnit && currentDriveUnitTravelStartRecords) {
            if (!currentDriveUnitTravelStartRecords.includes(item.start)) {
                currentDriveUnit.travelCounts++;
                currentDriveUnitTravelStartRecords.push(item.start);
            }
            currentDriveUnit.boxQuantity++;
            currentDriveUnit.totalHarvestedArea += item.loadedHectares || 0;
            currentDriveUnit.averageHarvestedArea = (currentDriveUnit.totalHarvestedArea / currentDriveUnit.travelCounts)
            currentDriveUnit.averageHarvestedAreaString = currentDriveUnit.averageHarvestedArea.toFixed(2);
            currentDriveUnit.totalHarvestedAreaString = currentDriveUnit.totalHarvestedArea.toFixed(2);
        } else {
            currentDriveUnitTravelStartRecords = [item.start];
            umTravelStart[`${item.driveUnitId}-${item.harvestFrontId}`] = currentDriveUnitTravelStartRecords;
            umMap[`${item.driveUnitId}-${item.harvestFrontId}`] = {
                driveUnitId: item.driveUnitId,
                driveUnitName: item.driveUnitName || '',
                harvestFrontName: item.harvestFrontName || '',
                travelCounts: 1,
                boxQuantity: 1,
                averageHarvestedArea: item.loadedHectares || 0,
                totalHarvestedArea: item.loadedHectares || 0,
                averageHarvestedAreaString: item.loadedHectares.toFixed(2) || `0`,
                totalHarvestedAreaString: item.loadedHectares.toFixed(2) || `0`,
                averageWeight: ``,
                totalWeight: ``,
                averageTCH: ``,
                totalTCH: ``,
                timeInEquipment: item.timeInEquipment || ''
            };
        }

        /**-------------- VIAJES------------ */
        let currentTravel = travelsMap[`${item.caneEquipmentId}-${item.start}`];
        if (currentTravel) {
            currentTravel.boxQuantity++;
            currentTravel.harvestedArea += item.loadedHectares;
            currentTravel.harvestedAreaString = currentTravel.harvestedArea.toFixed(2);
            if (currentTravel.lastDischageDate < item.dischargeDate && item.driveUnitName) {
                currentTravel.driveUnitName = item.driveUnitName || '';
                currentTravel.lastDischageDate = item.dischargeDate;
            }
        } else {
            travelsMap[`${item.caneEquipmentId}-${item.start}`] = {
                truckId: item.truckId,
                caneEquipmentId: item.caneEquipmentId,
                startTimestamp: item.start,
                fleetName: item.fleetName || '',
                driverName: item.driverName || '',
                truckName: item.truckName || '',
                caneEquipmentName: item.caneEquipmentName || '',
                harvestFrontName: item.harvestFrontName || '',
                driveUnitName: item.driveUnitName || '',
                start: item.startDate || '',
                harvestFrontIn: item.harvestFrontInDate || '',
                harvestFrontOut: item.harvestFrontOutDate || '',
                sugarmilIn: item.ingInDate || '',
                emptyIn: item.emptyInDate || '',
                boxQuantity: 1,
                harvestedArea: item.loadedHectares || 0,
                harvestedAreaString: item.loadedHectares.toFixed(2),
                weight: '',
                TCH: '',
                timeInEquipment: item.timeInEquipment || '',
                lastDischageDate: item.dischargeDate
            };
        }

        /**-------------- EQUIPO------------ */
        const equipmentkey = `${item.caneEquipmentId}-${item.harvestFrontId}-${item.driveUnitId}`;
        let currentEquipment = equipmentMap[equipmentkey];
        let currentEquipmentTravelStart = equipmentTravelStart[equipmentkey];
        if (!currentEquipmentTravelStart) {
            currentEquipmentTravelStart = new Set();
            currentEquipmentTravelStart.add(item.start);
            equipmentTravelStart[equipmentkey] = currentEquipmentTravelStart;
        }
        if (currentEquipment) {
            currentEquipmentTravelStart.add(item.start);
            currentEquipment.travelCounts = currentEquipmentTravelStart.size;
            currentEquipment.boxQuantity++;
            currentEquipment.totalHarvestedArea += item.loadedHectares || 0;
            currentEquipment.averageHarvestedArea = (currentEquipment.totalHarvestedArea / currentEquipment.travelCounts)
            currentEquipment.totalHarvestedAreaString = currentEquipment.totalHarvestedArea.toFixed(2);
            currentEquipment.averageHarvestedAreaString = currentEquipment.averageHarvestedArea.toFixed(2);
        } else {
            equipmentMap[equipmentkey] = {
                caneEquipmentId: item.caneEquipmentId,
                fleetName: item.fleetName || '',
                harvestFrontId: item.harvestFrontId,
                driveUnitId: item.driveUnitId,
                caneEquipmentName: item.caneEquipmentName || '',
                harvestFrontName: item.harvestFrontName || '',
                driveUnitName: item.driveUnitName || '',
                travelCounts: 1,
                boxQuantity: 1,
                averageHarvestedArea: item.loadedHectares || 0,
                totalHarvestedArea: item.loadedHectares || 0,
                totalHarvestedAreaString: item.loadedHectares.toFixed(2),
                averageHarvestedAreaString: item.loadedHectares.toFixed(2),
                averageWeight: 0,
                totalWeight: 0,
                averageTCH: 0,
                totalTCH: 0,
                timeInEquipment: item.timeInEquipment || ''
            };
        }

        /**-------------- FRENTE------------ */
        let currentFront = frontMap[`${item.harvestFrontId}-${item.driveUnitId}`];
        let currentFrontTravelStartRecords = frontTravelStart[`${item.harvestFrontId}-${item.driveUnitId}`];
        if (currentFront && currentFrontTravelStartRecords) {
            if (!currentFrontTravelStartRecords.includes(item.start)) {
                currentFront.travelCounts++;
                currentFrontTravelStartRecords.push(item.start);
            }
            currentFront.boxQuantity++;
            currentFront.totalHarvestedArea += item.loadedHectares;
            currentFront.averageHarvestedArea = (currentFront.totalHarvestedArea / currentFront.travelCounts);
            currentFront.averageHarvestedAreaString = currentFront.averageHarvestedArea.toFixed(2);
            currentFront.totalHarvestedAreaString = currentFront.totalHarvestedArea.toFixed(2);
        } else {
            currentFrontTravelStartRecords = [item.start];
            frontTravelStart[`${item.harvestFrontId}-${item.driveUnitId}`] = currentFrontTravelStartRecords;
            frontMap[`${item.harvestFrontId}-${item.driveUnitId}`] = {
                harvestFrontId: item.harvestFrontId,
                driveUnitId: item.driveUnitId,
                harvestFrontName: item.harvestFrontName || '',
                driveUnitName: item.driveUnitName || '',
                travelCounts: 1,
                boxQuantity: 1,
                workingHours: workingSecondsMap[item.harvestFrontId] ? ReportConversionUtil.secondsToStringFormatted(workingSecondsMap[item.harvestFrontId], "hh:mm:ss") : 0,
                averageHarvestedArea: item.loadedHectares || 0,
                totalHarvestedArea: item.loadedHectares || 0,
                averageHarvestedAreaString: item.loadedHectares.toFixed(2),
                totalHarvestedAreaString: item.loadedHectares.toFixed(2),
                averageWeight: 0,
                totalWeight: 0,
                averageTCH: 0,
                totalTCH: 0,
                timeInEquipment: item.timeInEquipment || ''
            };
        }

        /**-------------- Detalles------------ */
        details.push({
            truckId: item.truckId,
            caneEquipmentId: item.caneEquipmentId,
            driveUnitId: item.driveUnitId,
            fleetName: item.fleetName || '',
            driverName: item.driverName || '',
            truckName: item.truckName || '',
            caneEquipmentName: item.caneEquipmentName || '',
            harvestFrontName: item.harvestFrontName || '',
            harvestFrontId: item.harvestFrontId || '',
            harvesterName: item.harvesterName || '',
            tractorName: item.tractorName || '',
            driveUnitName: item.driveUnitName || '',
            harvestedArea: item.loadedHectares,
            harvestedAreaString: item.loadedHectares.toFixed(2),
            start: item.startDate || '',
            startTimestamp: item.start,
            harvestFrontIn: item.harvestFrontInDate || '',
            harvestFrontOut: item.harvestFrontOutDate || '',
            dischargeTrackTimestamp: item.dischargeDate || '',
            sugarMillIn: item.ingInDate || '',
            emptyIn: item.emptyInDate || '',
            secondsInEquipment: item.timeInEquipment || '',
        })
    })

    const umResult = Object.values(umMap)
    const travelResult = Object.values(travelsMap)
    const equipmentResult = Object.values(equipmentMap)
    const frontResult = Object.values(frontMap)

    return { umResult, travelResult, equipmentResult, frontResult, details }

}

const filterRepeated = (discharges) => {
    const result = [];
    const map = new Map();
    if (discharges && discharges.length > 0) {
        for (const discharge of discharges) {
            const key = `${discharge.tractorId}-${discharge.dischargeTrackTimestamp}`
            if (!map.get(key)) {
                result.push(discharge);
                map.set(key, { index: result.length - 1, data: discharge });
            } else {
                const repeated = map.get(key);
                if (repeated.data.loadedHectares < discharge.loadedHectares) {
                    repeated.data = discharge;
                    result[repeated.index] = discharge;
                }
            }
        }
    }
    return result;
}

export default { processReportData }