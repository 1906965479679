import { filterRulesMapped } from '@/tools/filterRules'
import { copyJson } from '@/tools/functions'
import { mapState, mapMutations, mapGetters } from 'vuex'
// import { dateSortFunction } from '@/tools/functions'
import service from '@/middleware'
import { DETAIL_HEADERS } from '../cdcConstants'

export default {
  name: 'CDCDetail',
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableHeaders: copyJson(DETAIL_HEADERS),
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'id',
    loadingTable: false,
    intervalId: null,
    middleware: service.http
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    }),
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    })
  },
  beforeDestroy() {
    this.resetEntitySelector()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  mounted() {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.tableData.cleanAndUpdate(this.model.details);
    this.setTableFilterRules(this.tableHeaders);
    if (this.selectedItem) {
      this.getData()
    }
  },
  methods: {
    ...mapMutations('app', {
      resetEntitySelector: 'resetEntitySelector'
    }),
    ...mapMutations('reportDefer', {
      commitVisible: 'commitVisible'
    }),
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    refreshTable() {
      console.log('refresh table')
      if (this.selectedItem) {
        this.getData(true)
      }
    },
    async getData(refresh = false) {
      console.log('getData executed')
      this.getDetail(this.selectedItem.id, this.selectedItem.type, refresh)
      if (this.model.autoReport) {
        const that = this
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          function () {
            that.getDetail(that.selectedItem.id, that.selectedItem.type, true)
          }, that.model.autoReportMinutes * 60 * 1000)
      }
    },
    getDetail(/*id, type, refresh = false*/) {
      this.loadingTable = true
      if (this.model.loadingSpinner) this.commitVisible({ visible: true })
      // TODO: Aquí se llama al detalle     
    },
    setTableFilterRules(headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
  },
  watch: {
    selectedItem() {
      if (this.selectedItem) {
        this.getData()
      }
    }
  }
}
